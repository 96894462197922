
import DxTextBox from "devextreme-vue/text-box";
import DefinePasswordControl from "@/components/controls/DefinePasswordControl.vue";
import DxButton from "devextreme-vue/button";
import { StoreManager } from "@/components/controls/catalogueGrid/storeManager";
import { GuidHelper } from "@/helpers/guidHelper/guidHelper";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    DxTextBox,
    DefinePasswordControl,
    DxButton,
    FontAwesomeIcon,
  },
  data() {
    return {
      changes: false,
      tempObject: {
        password: "",
        fullName: "",
      },
      renderKey: null,
      loading: true,
      saving: false,
      model: {},
      guidHelper: new GuidHelper(),
      storeManager: new StoreManager(),
      dataSource: null,
    };
  },
  methods: {
    haveChanges() {
      if (!this.loading)
        this.changes = !(
          JSON.stringify(this.tempObject) == JSON.stringify(this.model)
        );
    },
    reset() {
      this.tempObject = { ...this.model };
      this.haveChanges();
    },
    save() {
      const tempModel = { ...this.model };
      this.model = { ...this.tempObject };
      this.storeManager.setEditModel(this.renderKey, { ...this.model });
      this.saving = true;
      this.dataSource.update(this.model.id, { ...this.model }).then(
        () => {
          this.saving = false;
          this.model = { ...this.tempObject };
          this.haveChanges();
        },
        () => {
          this.saving = false;
          this.model = { ...tempModel };
          this.haveChanges();
        }
      );
    },
  },
  computed: {
    currentUser() {
      return (this as any).$store.state.auth.user;
    },
  },
  created() {
    this.renderKey = this.guidHelper.getUniqueId(4);
    this.dataSource = this.storeManager.getStandartGridCrudStore(
      "User",
      this.renderKey
    );
    this.dataSource.getEdit(this.currentUser.userid).then(
      (model: any) => {
        this.model = model;
        this.reset();
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  },
});
